<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{...$attrs}"
    :class="[`field-${field}`]"
    name="country-name"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import ValidationError from 'chimera/all/functions/ValidationError'
import AbstractFormField from 'chimera/all/components/form/fields/AbstractFormField'

export const field = 'address-country-name'
export default {
  name: 'AddressCountryNameField',

  extends: AbstractFormField,

  props: {
    field: {
      type: String,
      default: field
    },

    autofocus: {
      type: Boolean,
      default: false
    },

    autocomplete: {
      type: String,
      default: 'country-name'
    },

    placeholder: {
      type: String,
      default: ''
    }
  },

  /**
   * Component state
   *
   * @returns {object}
   */
  data () {
    return {
      value: ''
    }
  },

  watch: {
    /**
     * @param value
     */
    value (value) {
      this.reset()
    }
  },

  methods: {
    /**
     * @returns {*[]}
     */
    customErrorMap () {
      return [
        { id: ValidationError.reasonIsRequired, message: this.$i18n.t('field.address-country-name.validation.required') },
        { id: ValidationError.reasonInvalidRegex, message: this.$i18n.t('field.address-country-name.validation.invalid') },
        { id: ValidationError.reasonInvalidLength, message: this.$i18n.t('field.address-country-name.validation.invalid') },
        { id: ValidationError.reasonInvalidValue, message: this.$i18n.t('field.address-country-name.validation.invalid') }
      ]
    }
  }
}
</script>
