<template>
  <div>
    <FutureAddressFormGroup
      v-on="$listeners"
    />
  </div>
</template>

<script>
import FutureAddressFormGroup from 'chimera/moving/components/form/parts/futureAddress/FutureAddressFormGroup'

export default {
  name: 'MovingToAddressFormPart',

  components: {
    FutureAddressFormGroup
  }
}
</script>
