<template>
  <t-text-field
    :id="field"
    v-model="value"
    v-bind="{...$attrs}"
    :class="[`field-${field}`]"
    name="country-name"
    :autocomplete="autocomplete"
    :error-messages="errors"
    :placeholder="placeholder"
    outlined
    :autofocus="autofocus"
    :loading="loading"
    :success="isValid"
    @blur="validate(value)"
  />
</template>

<script>
import AddressCountryNameField from 'chimera/all/components/form/fields/addressCountryName/AddressCountryNameField'

export const field = 'future-address-country-name'
export default {
  name: 'FutureAddressCountryNameField',

  extends: AddressCountryNameField,

  props: {
    field: {
      type: String,
      default: field
    }
  }
}
</script>
