<template>
  <section>
    <div class="form-row space-x-4">
      <div class="w-2/3">
        <FutureAddressPostalRadioField
          :label="$t('field.postal.label')"
          placeholder=""
          @result="onResult"
          v-on="$listeners"
        />
      </div>

      <div class="w-1/3">
        <FutureAddressNumberField
          :label="$t('field.address-number.label')"
          placeholder=""
          @result="onResult"
          v-on="$listeners"
        />
      </div>
    </div>

    <div class="form-row">
      <FutureAddressStreetField
        ref="addressStreet"
        :label="$t('field.address-street.label')"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <FutureAddressCityField
        ref="addressCity"
        :label="$t('field.address-city.label')"
        placeholder=""
        autocomplete="no-fill"
        @result="onResult"
        v-on="$listeners"
      />
    </div>
  </section>
</template>

<script>
import FutureAddressPostalRadioField, { field as futureAddressPostalField } from 'chimera/all/components/form/fields/futureAddressPostal/FutureAddressPostalRadioField'
import FutureAddressNumberField from 'chimera/all/components/form/fields/futureAddressNumber/FutureAddressNumberField'
import FutureAddressStreetField from 'chimera/all/components/form/fields/futureAddressStreet/FutureAddressStreetField'
import FutureAddressCityField from 'chimera/all/components/form/fields/futureAddressCity/FutureAddressCityField'
import AddressFormGroup from 'chimera/all/components/form/parts/address/AddressFormGroup'

export default {
  name: 'FutureAddressFormGroup',

  components: {
    FutureAddressPostalRadioField,
    FutureAddressNumberField,
    FutureAddressStreetField,
    FutureAddressCityField
  },

  extends: AddressFormGroup,

  methods: {
    /**
     * Bubbles up the result emit from child to step
     * Triggers onPostalResult for postal fields
     *
     * @param {object} result
     * @param {object} result.field
     * @param {boolean} result.isValid
     * @param {string} result.value
     */
    onResult ({ field, isValid, value }) {
      if (field === futureAddressPostalField) {
        this.onPostalResult({ isValid, value })
      }
    }
  }
}
</script>
