<template>
  <div>
    <FutureAddressPropertyTypeRadioField
      :choices="choices"
      v-on="$listeners"
      @result="onPropertyTypeResult($event.value[0])"
    />

    <div v-if="showFloorLevelInput" class="form-row mt-3">
      <FutureAddressFloorLevelField
        :label="$t('field.address-floor-level.label')"
        placeholder=""
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import FutureAddressPropertyTypeRadioField from 'chimera/all/components/form/fields/futureAddressPropertyType/FutureAddressPropertyTypeRadioField'
import FutureAddressFloorLevelField, { field as futureAddressFloorLevelField } from 'chimera/all/components/form/fields/futureAddressFloorLevel/FutureAddressFloorLevelField'
import { Selectable } from 'chimera/all/components/models/Selectable'

export default {
  name: 'MovingToPropertyTypeFormPart',

  components: {
    FutureAddressPropertyTypeRadioField,
    FutureAddressFloorLevelField
  },

  /**
   * @returns {object}
   */
  data () {
    return {
      showFloorLevelInput: false,
      choices: [
        new Selectable(this.$t('house'), this.$t('house'), this.$t('house')),
        new Selectable(this.$t('apartment'), this.$t('apartment'), this.$t('apartment'))
      ]
    }
  },

  methods: {
    /**
     * @param {object} propertyType
     * @param {string} propertyType.id
     */
    onPropertyTypeResult ({ id }) {
      this.showFloorLevelInput = this.$i18n.t('apartment') === id

      if (!this.showFloorLevelInput) {
        this.$store.dispatch('lead/rm', futureAddressFloorLevelField)
      }
    }
  }
}
</script>

<i18n>
{
  "nl-NL": {
    "house": "Huis",
    "apartment": "Appartement"
  },
  "nl-BE": {
    "house": "Huis",
    "apartment": "Appartement"
  },
  "es-ES": {
    "house": "Casa",
    "apartment": "Apartamento"
  },
  "de-DE": {
    "house": "Haus",
    "apartment": "Wohnung"
  },
  "fr-BE": {
    "house": "Maison",
    "apartment": "Appartement"
  },
  "fr-FR": {
    "house": "Maison",
    "apartment": "Appartement"
  }
}
</i18n>
