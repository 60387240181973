<template>
  <FormModal
    title="Verhuizen naar"
    show-back-btn
  >
    <template #body>
      <MovingToFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import MovingToFormStep from '~/components/form/steps/movingTo/MovingToFormStep'

export default {
  components: {
    MovingToFormStep,
    FormModal
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data () {
    return {
      headTitle: 'Verhuizen naar',
      headDescription: 'Geef aan waar je gaat wonen.',
      path: '/offertes-aanvragen/verhuizen-naar',
      progressValue: 40,
      checkoutStep: 2
    }
  }
}
</script>
