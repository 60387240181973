<template>
  <div :class="[`field-${field}`]">
    <TSelectableGroup v-model="value" :choices="choices" :error-messages="errors" />
    <FormErrorMessages class="my-3" :error-messages="errors" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import TSelectableGroup from 'chimera/all/components/elements/TSelectableGroup'
import AddressPropertyTypeRadioField from 'chimera/all/components/form/fields/addressPropertyType/AddressPropertyTypeRadioField'

export const field = 'future-address-property-type'
export default {
  name: 'FutureAddressPropertyTypeRadioField',

  components: {
    TSelectableGroup,
    FormErrorMessages
  },

  extends: AddressPropertyTypeRadioField,

  props: {
    field: {
      type: String,
      default: field
    }
  }
}
</script>
